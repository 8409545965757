<template>
  <div>
    <b-overlay
      :show="overlayStatus"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-large
      rounded="sm"
      style="top: 25em;"
    ></b-overlay>
    <div v-if="companies.length === 0 && overlayStatus === false">
      <Empty
        :no-button="false"
        icon="LoaderIcon"
        title="No hay datos para mostrar"
        content="Actualmente no hay proveedores, para ver los tickets de cada proveedor, agrega uno primero."
        button-text="Crear un proveedor"
        :button-func="goToCreateProvider"
      />
    </div>
    <!-- Proveedores -->
    <b-tabs pills v-model="tabIndex">
        <b-tab v-for="company in companies" :key="company.companyId">
        <template #title>
          <feather-icon
            :icon="company.companyId ? 'UserIcon' : 'AlignCenterIcon'"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold"> {{ company.name }} </span>
        </template>
        <b-card no-body>
            <app-collapse>

                <app-collapse-item title="Filtros">
                    <b-card no-body class="fixed">
                        <div class="m-2">
                            <!-- Filtros -->
                            <b-row align-h="center" class="mt-2">
                                <b-col lg="4" md="4" sm="12">
                                    <b-form-group
                                            label="Fecha inicial"
                                    >
                                        <flat-pickr
                                                class="form-control d-inline-block ml-50 mr-1"
                                                :config="{ enableTime: true, dateFormat: 'd-m-Y H:i' }"
                                                v-model="startDate"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="4" sm="12">
                                    <b-form-group
                                            label="Fecha final"
                                    >
                                        <flat-pickr
                                                class="form-control d-inline-block ml-50 mr-1"
                                                :config="{ enableTime: true, dateFormat: 'd-m-Y H:i' }"
                                                v-model="endDate"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="4" sm="12">
                                    <b-form-group
                                            label="Cliente"
                                    >
                                        <v-select
                                                v-model="sellerCompanyId"
                                                label="name"
                                                :options="sellerCompanies"
                                                class="select-size-m"
                                                :reduce="option => option.companyId"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row align-h="center" class="mt-2">
                                <b-col lg="4" md="4" sm="12">
                                    <b-form-group
                                            label="Usario que recibio"
                                    >
                                        <v-select
                                                v-model="userId"
                                                label="name"
                                                :options="users"
                                                class="select-size-m"
                                                :reduce="option => option.userId"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="4" sm="12">
                                    <b-form-group
                                            label="Punto de venta"
                                    >
                                        <v-select
                                                v-model="posId"
                                                label="name"
                                                :options="pointOfSales"
                                                class="select-size-m"
                                                :reduce="option => option.posId"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col lg="4" md="4" sm="12">
                                    <b-form-group
                                            label="Bascula"
                                    >
                                        <v-select
                                                v-model="scaleId"
                                                label="name"
                                                :options="scales"
                                                class="select-size-m"
                                                :reduce="option => option.scaleId"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row align-h="center" class="mt-2">
                                <b-col lg="6" md="6" sm="12">
                                    <b-form-group
                                            label="Registros por página"
                                            label-for="per-page"
                                    >
                                        <v-select
                                                id="per-page"
                                                v-model="perPage"
                                                :options="pageOptions"
                                                :clearable="false"
                                                class="per-page-selector d-inline-block ml-50 mr-1"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col lg="6" md="6" sm="12">
                                    <b-button
                                            class="mt-1 mb-1"
                                            variant="primary"
                                            @click="findPurchaseOrdersByViewPurchaseOrdersFiltersApi(companies[tabIndex].companyId, tabIndex, (companies[tabIndex].companyId ? false : true))"
                                            :disabled=disabledButton
                                    >
                                        <feather-icon
                                                icon="SearchIcon"
                                                class="mr-50"
                                        />
                                        Buscar ordenes
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card>
                </app-collapse-item>
            </app-collapse>

          <!-- Tabla -->
<!--            :filter="shouldFilter[pos.posId] ? [generalFilters[pos.posId], dateFilters[pos.posId], statusFilters[pos.posId]] : null"-->
<!--            :filter-function="filterTable"-->
<!--            @filtered="onFiltered"-->
            <b-overlay :show="tableOverlays[tabIndex]" rounded="sm">
                <b-table
                        striped
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="purchaseOrders[tabIndex]"
                        :fields="tableColumns"
                        :sort-by.sync="sortBy"
                        :sortDesc="sortDesc"
                        empty-filtered-text="No hay tickets que cumplan con los filtros"
                        show-empty
                        empty-text="No hay registros"
                        class="position-relative"
                >
                    <!-- ID -->
                    <template #cell(shortPurchaseOrderId)="data">
                        <b>{{ data.item.purchaseOrder.shortPurchaseOrderId }}</b>
                    </template>

                    <!-- Fecha -->
                    <template #cell(createdAt)="data">
                        {{ new Date(data.item.purchaseOrder.createdAt) | moment("DD/MM/YYYY - H:mm") }}
                    </template>

                    <!-- Total -->
                    <template #cell(total)="data">
                        <span>Total: ${{ formatNumber(data.item.purchaseOrder.total) }}</span>
                    </template>

                    <!-- Acción -->
                    <template #cell(items)="row">
                        <b-button variant="light" size="sm" @click="toggleDetails(row)">
                            <feather-icon :icon=" row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'" class="mr-50" />
                            {{ row.detailsShowing ? 'Ocultar' : 'Mostrar' }}
                        </b-button>
                    </template>

                    <!-- Detalles -->
                    <template #row-details="row">
                        <b-card>
                            <b-row>
                                <b-col cols="12">
                                    <div class="table-responsive">
                                        <h3>Detalle de la transaccion</h3>
                                        <table class="table">
                                            <tbody>
                                            <tr>
                                                <td> Monto pagado </td>
                                                <td> Monto restante </td>
                                                <td> Demonicaciones dispensadas </td>
                                                <td> Errores </td>
                                                <td> Evidencia de entrega </td>
                                            </tr>
                                            <tr>
                                                <td><b>${{ row.item.paymentAuthorization.partialAmountPaid }}</b></td>
                                                <td><b>${{ row.item.paymentAuthorization.totalAmountDue}}</b></td>
                                                <td>{{ getMoneyDispensedPerPaymentAuthorization(row.item.paymentAuthorization.moneyDispensed) }}</td>
                                                <td>{{ row.item.paymentAuthorization.errorDetails }}</td>
                                                <td>
                                                    <template v-for="(video_ticket, index) in row.item.video_tickets">
                                                        <b-button variant="outline-dark" size="sm" @click="get_purchase_video_ticket_api(video_ticket)">
                                                            <feather-icon icon="ImageIcon" />
                                                        </b-button>
                                                        <br>
                                                    </template>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="table-responsive">
                                        <h3>Detalle de la orden</h3>
                                        <table class="table">
                                            <tbody>
                                            <tr>
                                                <td> Material </td>
                                                <td> Neto </td>
                                                <td> Precio </td>
                                                <td> Subtotal </td>
                                                <td v-if="$can('read', 'SnapshotsWeighing')"> Foto </td>
                                            </tr>
                                            <tr v-for="(item, index) in row.item.purchaseOrder.items" :key="index">
                                                <td><b> {{ item.name }} </b></td>
                                                <td>{{ item.quantity + "kgs"}}</td>
                                                <td>${{ item.price }}</td>
                                                <td>${{ item.subtotal }}</td>
                                                <div v-if="item.snapshot">
                                                    <b-button variant="outline-dark" size="sm" @click="showModal(item.snapshot)">
                                                        <feather-icon icon="ImageIcon" />
                                                    </b-button>
                                                </div>
                                                <div v-if="item.videoTicket">
                                                    <b-button variant="outline-dark" size="sm" @click="showVideoTicket(item.videoTicket)">
                                                        <feather-icon icon="ImageIcon" />
                                                    </b-button>
                                                </div>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>

                    <!-- Estatus de Pago -->
                    <template #cell(paymentStatus)="data">
                        <b-badge pill variant="success" v-if="data.item.paymentAuthorization.status === 'PAID'">
                            {{ data.item.paymentAuthorization.status }}
                        </b-badge>
                        <b-badge pill variant="warning" v-if="data.item.paymentAuthorization.status === 'PARTIALLY PAID'">
                            {{ data.item.paymentAuthorization.status }}
                        </b-badge>
                        <b-badge pill variant="primary" v-if="data.item.paymentAuthorization.status === 'CREATED'">
                            {{ data.item.paymentAuthorization.status }}
                        </b-badge>
                    </template>

                </b-table>
            </b-overlay>
          <!-- Paginación -->
          <div class="mx-2 mb-2" style="margin-top: 2em">
            <b-row>
              <b-col lg="12" sm="12" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-modal id="pictureModal" title="Evidencia de pesaje" ok-title="Cerrar" ok-only centered hide-header-close>
      <b-img
        :src="sourceImage"
        alt="Evidencia de pesaje"
        thumbnail
        fluid
        center
        width="500em"
        height="500em"
      />
    </b-modal>
    <b-modal id="videoTicketModal" title="Evidencia de ticket" ok-title="Cerrar" ok-only centered hide-header-close>
        <div>
            <b-embed type="video" aspect="4by3" controls>
                <source :src="ticketVideoUrl" type="video/mp4">
            </b-embed>
        </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker';

.per-page-selector {
  width: 90px;
}

.fixed {
  //position: sticky;
  //top: 5.5em;
  //z-index: 20;
  //margin-bottom: 0;
  //border-bottom: 3px solid #7367f0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BPagination,
  BButton,
  BOverlay,
  BModal,
  BImg,
  BTabs,
  BTab,
  BBadge,
  BSpinner,
    BEmbed
} from 'bootstrap-vue';
import Empty from '../../components/empty-page/Empty.vue'
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
flatpickr.localize(Spanish);

export default {
  components: {
      AppCollapseItem, AppCollapse,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    vSelect,
    flatPickr,
    BPagination,
    BButton,
    BOverlay,
    BModal,
    BImg,
    BTabs,
    BTab,
    BBadge,
    BSpinner,
    Empty,
      BEmbed
  },
  data() {
    return {
      // Table
      companies: [],
      tableColumns: [
        { key: 'shortPurchaseOrderId', label: 'ID', sortable: true, thStyle: { width: '1em' } },
        { key: 'createdAt', label: 'Fecha de creación', sortable: true },
        { key: 'total', label: 'Total', sortable: true },
        { key: 'paymentStatus', label: 'Estatus', sortable: true },
        { key: 'items', label: 'Detalles', sortable: false },
      ],
      sortBy: 'creationDate',
      sortDesc: true,

      disabledButton: true,

      // Data
      user: JSON.parse(localStorage.getItem("user")),
      purchaseOrders: [],

      sellerCompanies: [],
      users: [],
      pointOfSales: [],
      scales: [],

      sellerCompanyId: undefined,
      userId: undefined,
      posId: undefined,
      scaleId: undefined,
      startDate: this.$moment().startOf('day').format('DD-MM-YYYY HH:mm'),
      endDate: this.$moment().endOf('day').format('DD-MM-YYYY HH:mm'),

      perPage: 5,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      tableOverlay: false,
      tableOverlays: [],

      // Filters
      generalFilters: {},
      dateFilters: {},
      statusFilters: {},
      shouldFilter: {},
      statusOptions: [
        { name: 'CREATED', },
        { name: 'PAID', },
        { name: 'PARTIALLY PAID', },
      ],
      ticketVideoUrl: undefined,

      // Props
      userData: JSON.parse(localStorage.getItem('userData')),
      overlayStatus: true,
      sourceImage: null,
      videoTicket: null,
      finishedProviders: 0,
      tabIndex: 0,
      spinButtonNames: ['Bateria', 'Cubeta'],
      paymentStatuses: new Map([
        [1, ['INACTIVO', 'dark']],
        [2, ['ACTIVO', 'light']],
        [3, ['CONTADO', 'success']],
        [4, ['CREDITO', 'warning']],
        [5, ['SIN ENVIAR', 'danger']],
        [6, ['EN CURSO', 'warning']],
        [7, ['ENTREGADO', 'success']],
        [8, ['SIN COBRAR', 'danger']],
        [9, ['PARCIAL', 'warning']],
        [10, ['COBRADO', 'success']],
        [11, ['AJUSTE', 'info']],
      ]),
    };
  },
  async created() {
    this.findCompaniesSellerApi()
    this.findPosUsersByCompanyApi()
    this.findPosByCompanyIdApi()
    this.findScalesByCompanyIdApi()
    this.getCompanies();
    // await this.getCompanies();
    // this.refresh = setInterval(this.automaticRefresh, 15000);  // Cada 15 segundos
    //
    // // Quitar proveedores extra si no eres broker, y traer sus tickets
    // if (this.$can('read', 'Calculator')) {
    //   this.providers = this.providers.filter((provider) => provider.idCompany == this.userData.idCompany);
    //   this.getPurchaseTickets(this.userData.idCompany);
    // } else {
    //   // Llamar a todos los proveedores
    //   const promises = [];
    //   this.providers.forEach((provider) => {
    //     if (provider.companyName !== "General") {
    //       promises.push(this.getPurchaseTickets(provider.idCompany));
    //     }
    //   });
    //   await Promise.all(promises);
    //
    //   // Setear datos en pestaña general
    //   this.getGeneralData();
    // }
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.refresh);
    next();
  },
  methods: {
    async toggleDetails(row) {
        if (!row.detailsShowing) {
            row.item.video_tickets = await this.findPurchaseOrderTicketVideosApi(row.item.purchaseOrder.purchaseOrderId)
        }
        row.toggleDetails();
    },
    selectPos(){
      const posId = this.pointOfSales[this.tabIndex]?.posId
      this.getPurchaseOrdersByPosId(posId)
    },
    getPurchaseOrdersByPosId(posId) {
        this.$axios
            .post(this.$services + "manager/findPurchaseOrdersByPosIdApi", {
                posId: posId,
            })
            .then(res => {
                if (!res.data.error) {
                    this.$set(this.purchaseOrders, posId, res.data);
                    this.$set(this.totalRows, posId, res.data.length);
                }
            }).finally(() => {
            this.overlayStatus = false;
        });
    },
    getMoneyDispensedPerPaymentAuthorization(moneyDispensed){
        let moneyDispensedStr = ""
        moneyDispensed.map(money => {
            moneyDispensedStr += `$${money.denomination}x${money.quantity}\n`
        })
        return moneyDispensedStr
    },
    getCompanies() {

        this.companies.push({"name": this.user.companyName, companyId: this.user.companyId });
        this.purchaseOrders = new Array(this.companies.length).fill([]);
        this.tableOverlays = new Array(this.companies.length).fill(true);
        this.companies = this.companies.filter(company => company.companyId === this.user.companyId);
        this.findPurchaseOrdersByViewPurchaseOrdersFiltersApi(this.user.companyId, 0, false);
        this.overlayStatus = false;
        // const userData = JSON.parse(localStorage.getItem('userData'));
        // this.$axios.get(this.$services + 'companies/get_companies').then(res => {
        //   this.$refreshToken(res.headers['x-access-token']);
        //   if (!res.error) {
        //
        //     // General + extras
        //     this.companies.push({"name": "General"});
        //     this.companies.push(...res.data.data);
        //     this.dailyCuts = new Array(this.companies.length).fill(new Array());
        //     this.tableOverlays = new Array(this.companies.length).fill(true);
        //
        //     // Quitar proveedores extra si no eres broker, y llamar su corte diario
        //     if (this.$can('read', 'Calculator')) {
        //       this.companies = this.companies.filter(company => company.companyId == userData.companyId);
        //       this.getDailyCut(userData.companyId, 0, false);
        //     } else {
        //       // Llamar daily cut para todos los proveedores
        //       this.getDailyCut(null, null, null);
        //     }
        //   }
        // }).catch(error => {
        //   console.log(error);
        //   this.$verifyToken(error.response.data.data);
        // }).finally(() => {
        //   this.overlayStatus = false;
        // });
    },
    findPurchaseOrdersByViewPurchaseOrdersFiltersApi(companyId, index, general) {
        this.disabledButton = true;
        if (companyId) {
            this.$set(this.tableOverlays, index, true);
            this.$axios.post(this.$services + 'manager/findPurchaseOrdersByViewPurchaseOrdersFiltersApi', {
                sellerCompanyId: this.sellerCompanyId,
                userId: this.userId,
                posId: this.posId,
                scaleId: this.scaleId,
                buyerCompanyId: companyId,
                startDate: this.$moment(this.startDate, 'DD-MM-YYYY HH:mm').utc().format(),
                endDate: this.$moment(this.endDate, 'DD-MM-YYYY HH:mm').utc().format(),
            }).then(res => {
                if (res.data) {
                    // General
                    // if (general) {
                    // this.dailyCuts[0].totalAmount = res.data.totalAmount;
                    // this.dailyCuts[0].totalQuantity = res.data.totalQuantity;

                    // this.setGeneralInformation(res.data);
                    // }
                    const purchaseOrders = res.data
                    this.purchaseOrders.splice(index, 1, purchaseOrders);
                    this.totalRows = res.data.length;
                    // this.finishedCompanies += 1;
                    //
                    // if (this.finishedCompanies == this.companies.length - 1) {
                    //   this.$set(this.tableOverlays, 0, false);
                    // }
                }
            }).finally(() => {
                this.$set(this.tableOverlays, index, false);
                this.disabledButton = false;
            });
        }
    },
    getGeneralData() {
      // Agrupar los datos de todos los proveedores
      const data = [];
      this.providers.forEach((provider) => {
        if (provider.companyName !== "General") {
          data.push(...this.providerItems[provider.idCompany]);
        }
      });

      // Setear esos datos
      this.$set(this.providerItems, -1, data);
      this.$set(this.totalRows, -1, data.length);
      this.$set(this.tableOverlays, -1, false);
    },
    async getLastTickets(idCompany) {
      // Parar contador
      clearInterval(this.refresh);

      if (idCompany === -1) {  // General
        this.$set(this.tableOverlays, -1, true);

        const promises = [];
        this.providers.forEach((provider) => {
          if (provider.companyName !== "General") {
            promises.push(this.getPurchaseTickets(provider.idCompany));
          }
        });
        await Promise.all(promises);

        this.getGeneralData();
      } else {  // Un sólo proveedor
        this.getPurchaseTickets(idCompany);
      };

      // Reiniciar contador
      this.refresh = setInterval(this.automaticRefresh, 15000);  // Cada 15 segundos
    },
    automaticRefresh() {
      // Refrescar la pestaña actualmente activa de forma automática
      if (this.providers) {
        this.getLastTickets(this.providers[this.tabIndex].idCompany);
      }
    },
    resetTimer() {
      // Reiniciar el contador para el refresco automático
      clearInterval(this.refresh);
      this.refresh = setInterval(this.automaticRefresh, 15000);  // Cada 15 segundos
    },
    filterTable(row, filter) {
      // Conseguir filtros
      const [general, date, status] = filter;

      // Ignorar fecha o estatus en caso de que estén vacías
      const ignoreStatus = status ? false : true;
      const ignoreDate = date ? false : true;
      let start, finish, rowDate;

      // Separar fecha en inicio y final
      if (!ignoreDate) {
        if (date.length > 10) {
          start = date.split(" a ")[0];
          finish = date.split(" a ")[1];
        } else {
          start = date;
          finish = start;
        }

        // Adecuar las fechas
        start = this.$moment(start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        finish = this.$moment(finish).set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
        rowDate = this.$moment(row.creationDate);
      }

      // Checar fila por fila si se debe de filtrar
      if ((row.shortOrderId.toLowerCase().includes(general.toLowerCase()) ||
          row.items.some((item) => item.itemName.toLowerCase().includes(general.toLowerCase()))) &&
          (ignoreStatus || row.paymentStatus == status) &&
          (ignoreDate || ((rowDate).isSameOrAfter(start) && (rowDate).isSameOrBefore(finish)))
        ) {
        return true;
      } else {
        return false;
      }
    },
    onFiltered(filteredItems) {
      // Ajustar la paginación de las tablas al ser filtradas
      this.$set(this.totalRows, this.pointOfSales[this.tabIndex].posId, filteredItems.length);
      this.currentPage = 1
    },
    updateShouldFilter() {
        console.log(this.generalFilters)
      // Si alguno de sus filtros está activo, filtrar esta compañía
      for (const idCompany in this.generalFilters) {
        if (this.generalFilters[idCompany] || this.dateFilters[idCompany] || this.statusFilters[idCompany]) {
          this.shouldFilter[idCompany] = true;
        } else {
          this.shouldFilter[idCompany] = false;
        }
      }
    },
    get_purchase_video_ticket_api(filePath){
        this.$axios
        .post(this.$services + "manager/getPurchaseOrderTicketVideoApi", {
            filePath: filePath,
        })
        .then((res) => {
            if (!res.data.error) {
                this.ticketVideoUrl = res.data;
                this.$bvModal.show('videoTicketModal');
            }
        })
    },
    async findPurchaseOrderTicketVideosApi(purchaseOrderId){
        const response  = await this.$axios
            .post(this.$services + "manager/findPurchaseOrderTicketVideosApi", {
                purchaseOrderId: purchaseOrderId,
            })
        return response.data
    },
    showModal(snapshot) {
      this.sourceImage = snapshot;
      this.$bvModal.show('pictureModal');
    },
    goToCreateProvider() {
      this.$router.push({ name: 'add-provider' });
    },
    formatNumber(value) {
      const number = this.$BigNumber(value);
      return number.toFormat(0, this.$BigNumber.ROUND_DOWN);
    },
    findCompaniesSellerApi() {
        this.$axios
            .post(this.$services + 'manager/findCompaniesSellerApi', {
                companyId: this.user.companyId
            })
            .then((res) => {
                this.sellerCompanies = res.data;
            })
    },
    findPosUsersByCompanyApi() {
        this.$axios
            .post(this.$services + 'manager/findPosUsersByCompanyApi', {
                companyId: this.user.companyId
            })
            .then((res) => {
                this.users = res.data;
            })
    },
    findPosByCompanyIdApi() {
        this.$axios
            .post(this.$services + 'manager/findPosByCompanyIdApi', {
                companyId: this.user.companyId
            })
            .then((res) => {
                this.pointOfSales = res.data;
            })
    },
    findScalesByCompanyIdApi() {
        this.$axios
            .post(this.$services + 'manager/findScalesByCompanyIdApi', {
                companyId: this.user.companyId
            })
            .then((res) => {
                this.scales = res.data;
            })
    },
  },
  watch: {
    'generalFilters': {
      handler: 'updateShouldFilter',
      deep: true,
    },
    'dateFilters': {
      handler: 'updateShouldFilter',
      deep: true,
    },
    'statusFilters': {
      handler: 'updateShouldFilter',
      deep: true,
    },
  },
};
</script>
